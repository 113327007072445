import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { toSlug } from '@medifind/router';
import { useRouter } from '@medifind/router';
import { LocationFilter, MagicFilter } from '@medifind/search-results';
import { Button } from '@medifind/shared-basic-components';
import { useMediaQueryUp } from '@medifind/utils';
import { logEventGA4 } from '@medifind/zustand';

const FindADoctorForm = ({ heading, classes = {}, showWrapper }) => {
  const router = useRouter();
  const [error, setError] = useState({});
  const [input, setInput] = useState();
  const mediumBreakpoint = useMediaQueryUp('lg');

  const handleSubmit = (value) => {
    if (!value) {
      setError({ ...error, input: 'Required field' });
    } else {
      if (typeof value === 'object') {
        navigateByCategory(value);
      } else {
        logEventGA4('search', { type: 'doctor-name' });
        router.history.push(`/find-a-doctor?isStarted=true&name=${value}`);
      }
      setError({});
    }
  };

  const navigateByCategory = (event) => {
    const { key, personId, name, value, display, codingType, link } = event;
    if (key === 'hint') {
      logEventGA4('search', { type: 'hint' });
      router.history.push(`/find-a-doctor?isStarted=true&name=${link}`);
    } else if (key === 'doctors') {
      const doctorSlug = toSlug(name);
      logEventGA4('search', { type: 'doctor' });
      return router.history.push(`/doctors/${doctorSlug}/${personId}`);
    } else if (key === 'conditions') {
      if (codingType === 'procedure') {
        const codingSlug = toSlug(display);
        logEventGA4('search', { type: 'procedure' });
        return router.history.push(`/procedures/${codingSlug}/${value}/doctors`);
      } else {
        const codingSlug = toSlug(display);
        logEventGA4('search', { type: 'condition' });
        return router.history.push(`/conditions/${codingSlug}/${value}/doctors`);
      }
    } else if (key === 'specialties') {
      const slug = value;
      logEventGA4('search', { type: 'specialty' });
      return router.history.push(`/specialty/${slug}`);
    }
  };

  const setLocationError = (err) => {
    setError({ ...error, location: err });
  };

  return (
    <div className={classes['formContainer']}>
      <div className={classes['formInputGroup']}>
        <div className={classes['formField']} style={{ flex: 1.5 }}>
          <MagicFilter
            id="find-a-doctor-form-magic-filter"
            showHeader={false}
            placeholder={'Enter a condition, specialty, or name'}
            classes={{
              ...classes,
              menuWrapper: classes.formInputText,
              filter: classes.formInput,
              input: classes.formInputField,
              menu: classes.formDropdown,
              inputWrapper: classNames(classes.formInputText, { [classes.formInputError]: error.input }),
            }}
            menuOpenWithBorder={true}
            showWrapper={showWrapper}
            activeFirstOption={true}
            fillInput={!mediumBreakpoint}
            searchFor={['specialties', 'conditions', 'doctors']}
            onEnter={(value) => handleSubmit(value)}
            onInputChange={(value) => setInput(value)}
            onChange={async (event) => {
              if (event) {
                navigateByCategory(event);
              }
            }}
            highlightedItem={(item) => setInput(item)}
          />
          {error.input && <div className={classes['formError']}>{error.input}</div>}
        </div>
        <div className={classes['formField']}>
          <LocationFilter
            className={classes.formInput}
            noHeader={true}
            classes={{
              ...classes,
              menuWrapper: classes.formInputText,
              filter: classes.formInput,
              input: classes.formInputField,
              menu: classes.formDropdown,
              inputWrapper: classNames(classes.formInputText, { [classes.formInputError]: error.location }),
            }}
            menuOpenWithBorder={true}
            showWrapper={showWrapper}
            // showGetLocationButton to follow new design
            showIconPreInput
            showClearButton
            placeholder="Enter city, state or zip code"
            onError={setLocationError}
            disableErrorMessage
          />
          {error.location && <div className={classes['formError']}>{error.location}</div>}
        </div>
      </div>
      <Button
        classes={{ container: classes['formSubmitButton'], content: classes['formSubmitButtonContent'] }}
        type="thin"
        color="blue"
        label={'Search'}
        onClick={() => handleSubmit(input)}
      />
    </div>
  );
};

FindADoctorForm.propTypes = {
  heading: PropTypes.node,
  classes: PropTypes.object,
  placeholder: PropTypes.string,
  showWrapper: PropTypes.bool,
};

export default FindADoctorForm;
